// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DashboardTabsCss from "../DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function DashboardTabBody(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: DashboardTabsCss.Tab.Body.container
            });
}

var Css;

var make = DashboardTabBody;

export {
  Css ,
  make ,
}
/* DashboardTabsCss Not a pure module */
