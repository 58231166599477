// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as DashboardTabsCss from "../DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function backgroundToClass(background) {
  if (background === "Gray") {
    return Cx.cx([
                DashboardTabsCss.Tab.$$Highlight.container,
                DashboardTabsCss.Tab.$$Highlight.grayBackground
              ]);
  } else {
    return DashboardTabsCss.Tab.$$Highlight.container;
  }
}

function DashboardTabsHighlight(props) {
  var __background = props.background;
  var background = __background !== undefined ? __background : "Transparent";
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: backgroundToClass(background)
            });
}

var Css;

var make = DashboardTabsHighlight;

export {
  Css ,
  backgroundToClass ,
  make ,
}
/* DashboardTabsCss Not a pure module */
