// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DashboardTabsCss from "../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function DashboardAgencyShowAgents(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("span", {
                          children: "Agents are coming soon!",
                          className: DashboardTabsCss.Tab.Section.comingSoon
                        }),
                    className: DashboardTabsCss.Tab.Section.container
                  }),
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var make = DashboardAgencyShowAgents;

export {
  Css ,
  make ,
}
/* DashboardTabsCss Not a pure module */
