// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var header = Cx.cx([
      "flex",
      "flex-row",
      "justify-between",
      "w-full"
    ]);

var heading = Cx.cx([
      "flex",
      "flex-col",
      "items-start",
      "gap-2"
    ]);

var title = Cx.cx([
      "flex",
      "flex-col",
      "text-2xl",
      "font-bold"
    ]);

var titleSpan = Cx.cx([
      "flex",
      "items-start",
      "gap-2",
      "uppercase",
      "text-base",
      "text-gray-400",
      "font-medium",
      "mb-2"
    ]);

var editLink = Cx.cx([
      "text-base",
      "text-gray-400",
      "hover:text-teal",
      "font-medium"
    ]);

export {
  header ,
  heading ,
  title ,
  titleSpan ,
  editLink ,
}
/* header Not a pure module */
