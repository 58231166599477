// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DashboardTabsCss from "./DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function DashboardTabs(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: DashboardTabsCss.container
            });
}

var Css;

var make = DashboardTabs;

export {
  Css ,
  make ,
}
/* DashboardTabsCss Not a pure module */
