// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DashboardTabsCss from "../DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function tabTypeToClass(tabType) {
  if (tabType === "Selected") {
    return DashboardTabsCss.Tab.MenuItem.selected;
  } else {
    return DashboardTabsCss.Tab.MenuItem.notSelected;
  }
}

function DashboardTabMenuItem(props) {
  var __tabType = props.tabType;
  var tabType = __tabType !== undefined ? __tabType : "NotSelected";
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: props.title,
                    className: tabTypeToClass(tabType)
                  }),
              className: DashboardTabsCss.Tab.MenuItem.container,
              onClick: props.onClick
            });
}

var Css;

var make = DashboardTabMenuItem;

export {
  Css ,
  tabTypeToClass ,
  make ,
}
/* DashboardTabsCss Not a pure module */
