// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var badge = Cx.cx([
      "inline-flex",
      "gap-2",
      "items-center",
      "rounded-md",
      "font-medium",
      "w-auto"
    ]);

var gray = Cx.cx([
      badge,
      "bg-gray-100",
      "text-gray-600"
    ]);

var red = Cx.cx([
      badge,
      "bg-red-100",
      "text-red-600"
    ]);

var green = Cx.cx([
      badge,
      "bg-green-100",
      "text-green-600"
    ]);

var blue = Cx.cx([
      badge,
      "bg-blue-100",
      "text-blue-700"
    ]);

var yellow = Cx.cx([
      badge,
      "bg-yellow-100",
      "text-yellow-900"
    ]);

var small = Cx.cx([
      "px-2 py-1",
      "text-xs"
    ]);

var medium = Cx.cx([
      "px-3 py-2",
      "text-base"
    ]);

var large = Cx.cx([
      "px-4 py-3",
      "text-lg"
    ]);

var disabled = "opacity-25";

export {
  badge ,
  gray ,
  red ,
  green ,
  blue ,
  yellow ,
  small ,
  medium ,
  large ,
  disabled ,
}
/* badge Not a pure module */
