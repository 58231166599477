// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "w-full"
    ]);

var container$1 = Cx.cx([
      "flex",
      "flex-col",
      "gap-5"
    ]);

var label = Cx.cx([
      "text-gray-700",
      "text-lg",
      "font-medium"
    ]);

var searchContainer = Cx.cx([
      "flex",
      "flex-row",
      "justify-between"
    ]);

var container$2 = Cx.cx([
      "flex",
      "flex-row",
      "gap-4",
      "border-b",
      "border-b-gray-300",
      "py-4"
    ]);

var Menu = {
  container: container$2
};

var container$3 = Cx.cx(["flex"]);

var menuItem = Cx.cx([
      "font-medium",
      "hover:text-purple",
      "cursor-pointer"
    ]);

var notSelected = Cx.cx([
      menuItem,
      "text-gray-400"
    ]);

var selected = Cx.cx([
      menuItem,
      "text-black"
    ]);

var MenuItem = {
  container: container$3,
  menuItem: menuItem,
  notSelected: notSelected,
  selected: selected
};

var container$4 = Cx.cx([
      "flex",
      "flex-col",
      "py-4"
    ]);

var Body = {
  container: container$4
};

var container$5 = Cx.cx([
      "flex",
      "flex-col",
      "gap-5",
      "border-b",
      "border-b-gray-100",
      "pb-5",
      "last:border-b-0"
    ]);

var dateDetails = Cx.cx([
      "flex",
      "flex-row",
      "items-center",
      "gap-2",
      "text-sm",
      "font-medium",
      "text-gray-400"
    ]);

var description = Cx.cx([
      "flex",
      "flex-col",
      "gap-2"
    ]);

var p = Cx.cx(["text-gray-400"]);

var Section = {
  container: container$5,
  dateDetails: dateDetails,
  description: description,
  p: p,
  comingSoon: "text-red"
};

var highlightsContainer = Cx.cx([
      "p-4",
      "rounded",
      "border",
      "border-gray-100"
    ]);

var container$6 = Cx.cx([
      "grid",
      "grid-cols-3",
      "justify-stretch",
      "p-3"
    ]);

var grayBackground = Cx.cx([
      "bg-gray-100",
      "rounded-sm",
      "drop-shadow"
    ]);

var label$1 = Cx.cx([
      "text-gray-600",
      "col-span-1",
      "font-medium"
    ]);

var passwordLabel = Cx.cx([
      label$1,
      "flex",
      "flex-row",
      "gap-2",
      "items-center",
      "cursor-pointer",
      "hover:text-teal"
    ]);

var data = Cx.cx([
      "text-gray-600",
      "col-span-2"
    ]);

var link = Cx.cx([
      "text-teal",
      "hover:text-purple"
    ]);

var $$Highlight = {
  highlightsContainer: highlightsContainer,
  container: container$6,
  grayBackground: grayBackground,
  label: label$1,
  passwordLabel: passwordLabel,
  data: data,
  link: link
};

var menu = Cx.cx([
      "flex",
      "flex-wrap",
      "items-center",
      "justify-start"
    ]);

var dropdown = Cx.cx([
      "grid",
      "grid-flow-col",
      "gap-4",
      "items-center"
    ]);

var body = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "min-w-60"
    ]);

var bodyLink = Cx.cx(["hover:text-teal"]);

var bodyRow = Cx.cx([
      "grid",
      "!grid-cols-[max-content_1fr]",
      "gap-1.75",
      "items-center",
      "py-1.75",
      "border-b",
      "border-gray-100"
    ]);

var Dropdown = {
  menu: menu,
  dropdown: dropdown,
  body: body,
  bodyLink: bodyLink,
  bodyRow: bodyRow
};

var link$1 = Cx.cx([
      "font-medium",
      "hover:text-teal"
    ]);

var Table = {
  link: link$1
};

var Tab = {
  container: container$1,
  label: label,
  pagination: "mb-10",
  searchContainer: searchContainer,
  Menu: Menu,
  MenuItem: MenuItem,
  Body: Body,
  Section: Section,
  $$Highlight: $$Highlight,
  Dropdown: Dropdown,
  Table: Table
};

export {
  container ,
  Tab ,
}
/* container Not a pure module */
