// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as BadgeCss from "./BadgeCss.res.js";
import * as BadgeScss from "./Badge.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = BadgeScss;

function Badge(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var tmp;
  switch (props.badgeType) {
    case "Colocation" :
        tmp = css.colorColocation;
        break;
    case "Cloud" :
        tmp = css.colorCloud;
        break;
    case "Internet" :
        tmp = css.colorInternet;
        break;
    case "Network" :
        tmp = css.colorNetwork;
        break;
    case "BareMetal" :
        tmp = css.colorBareMetal;
        break;
    case "Default" :
        tmp = css.colorDefault;
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("button", {
                    children: props.children,
                    className: Cx.cx([
                          css.badge,
                          tmp,
                          className
                        ]),
                    disabled: true
                  }),
              className: css.wrapper
            });
}

function Badge$Span(props) {
  var __className = props.className;
  var __size = props.size;
  var __enabled = props.enabled;
  var __id = props.id;
  var id = __id !== undefined ? __id : "";
  var enabled = __enabled !== undefined ? __enabled : true;
  var size = __size !== undefined ? __size : "SM";
  var className = __className !== undefined ? __className : "";
  var tmp;
  switch (props.color) {
    case "Gray" :
        tmp = BadgeCss.gray;
        break;
    case "Red" :
        tmp = BadgeCss.red;
        break;
    case "Green" :
        tmp = BadgeCss.green;
        break;
    case "Blue" :
        tmp = BadgeCss.blue;
        break;
    case "Yellow" :
        tmp = BadgeCss.yellow;
        break;
    
  }
  var tmp$1;
  switch (size) {
    case "SM" :
        tmp$1 = BadgeCss.small;
        break;
    case "MD" :
        tmp$1 = BadgeCss.medium;
        break;
    case "LG" :
        tmp$1 = BadgeCss.large;
        break;
    
  }
  return JsxRuntime.jsx("span", {
              children: props.children,
              className: Cx.cx([
                    tmp,
                    tmp$1,
                    enabled ? "" : BadgeCss.disabled,
                    className
                  ]),
              id: id
            });
}

var Span = {
  Css: undefined,
  make: Badge$Span
};

var make = Badge;

export {
  css ,
  make ,
  Span ,
}
/* css Not a pure module */
